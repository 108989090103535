/* eslint-disable indent */
import { FC } from 'react';
import Header from 'components/Header';
import { useSettings } from 'hooks/SettingsContext';
import useWindowSize from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './surveySuccessful.module.scss';
import noShoes from '../../assets/no-shoes.png';
import handSanitizer from '../../assets/handSanitizer.png';
import { useTimer } from 'hooks/TimerContext';
import { Wrapper } from '../../components/Wrapper';

const SurveySuccessful: FC = () => {
    const { t } = useTranslation();
    const { settings } = useSettings();

    const infoIcons = settings?.config.isChildrenClinic
        ? [
              { img: noShoes, translationKey: 'check_in_successful_step_1' },
              { img: handSanitizer, translationKey: 'check_in_successful_step_2' },
          ]
        : [{ img: handSanitizer, translationKey: 'check_in_successful_step_2' }];
    const { isMobile } = useWindowSize();
    const navigate = useNavigate();
    const { startTimer } = useTimer();
    return (
        <>
            <Header />
            <Wrapper gradient>
                <p className={styles.surveyHeaderText}>{t('survey_successful_header')}</p>
                <p className={styles.surveyMessageText}>{t('survey_successful_message')}</p>
                <div className={styles.surveyIconsRow}>
                    <p className={styles.surveyStepsText}>{t('survey_successful_steps')}</p>
                    <div className={styles.iconsContainer}>
                        {infoIcons.map((icon, index) => (
                            <div key={index} className={styles.surveyIconWrapper}>
                                <div className={styles.surveyIcon}>
                                    <img className={styles.surveyIconImage} src={icon.img} alt="" />
                                </div>
                                <p>{t(icon.translationKey)}</p>
                            </div>
                        ))}
                    </div>
                </div>
                {isMobile ? (
                    <p className={styles.surveyMessageText}>{t('close_window_message')}</p>
                ) : (
                    <button
                        className={styles.surveySuccessfulNewCheckInButton}
                        onClick={() => {
                            if (!settings) return;
                            navigate('/check-in');
                            const secondsTilRedirect = settings.config.timer;
                            startTimer(secondsTilRedirect, () => navigate('/screensaver'));
                        }}
                    >
                        {t('check_in_successful_button_new_checkin')}
                    </button>
                )}
            </Wrapper>
        </>
    );
};

export default SurveySuccessful;
