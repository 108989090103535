import React, { useEffect, useState } from 'react';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import styles from './payment.module.scss';
import Header from 'components/Header';
import { useTranslation } from 'react-i18next';
import { updateInvoiceService } from 'services/invoice.service';
import { useSettings } from 'hooks/SettingsContext';
import { useNavigate } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import { Card } from 'components/Card';
import { Button } from 'components/Button';
import { getSwishQRService } from 'services/swish.service';
import { Box } from 'components/Box';
import { Wrapper } from 'components/Wrapper';
import { CheckIcon } from 'components/icons/check';
import { updateFirestoreAppointment } from 'firebase-service';
import { sendInvoiceErrorNotification } from '../../services/notification.service';
import { Skeleton } from '../../components/Skeleton/skeleton.component';
import { useAuth } from 'hooks/AuthContext';

const Payment = () => {
    const { clinic } = useAuth();
    const [loading, updateLoading] = useState(true);
    const [error, updateError] = useState(false);
    const [success, updateSuccess] = useState(false);
    const { t } = useTranslation();
    const { settings } = useSettings();
    const navigate = useNavigate();
    const { isMobile } = useWindowSize();
    const [swishQr, setSwishQr] = useState<string>();
    const [swishQrError, updateSwishQRError] = useState(false);
    const nextPage = isMobile ? '/survey' : '/checkin-successful';
    const visitData = JSON.parse(localStorage.getItem('visitData') as string);
    const swishData = JSON.parse(localStorage.getItem('swishData') as string);
    const { invoiceData, appointmentId } = visitData;
    const { id, token } = swishData;
    const invoiceId = settings?.webDocIntegration ? invoiceData[0].invoiceId : '';
    const invoiceNumber = settings?.webDocIntegration ? invoiceData[0].invoiceNumber : '';
    const netAmount = settings?.webDocIntegration ? invoiceData[0].netAmount : '100';

    const getSwishQr = async () => {
        const res = await getSwishQRService(token);
        if (!res.error) {
            setSwishQr(res);
            updateLoading(false);
        }
        if (res.error) {
            updateSwishQRError(true);
        }
    };
    useEffect(() => {
        getSwishQr();
    }, []);

    const updateInvoiceStatus = async () => {
        if (!settings) return;
        const res = await updateInvoiceService(settings.webDocClinicId, invoiceId, netAmount);

        if (res.error) {
            await sendInvoiceErrorNotification(
                invoiceNumber,
                settings.config.email,
                t('invoice_failure_notification'),
                t('invoice_failure_subject')
            );
        }
    };
    const updatePaymentStatus = async (status: boolean) => {
        if (!clinic) return;
        if (!status) {
            updateError(!error);
            return;
        }
        updateSuccess(!success);
        await updateFirestoreAppointment(clinic, appointmentId, { arrived: 2, invoiceNumber });
        if (settings?.webDocIntegration) {
            await updateInvoiceStatus();
        }
        navigate(`${nextPage}/${appointmentId}`);
    };
    useEffect(() => {
        const db = getFirestore();
        const subscribeSwishPayment = onSnapshot(
            doc(db, 'swishPaymentRequests', id),
            async (doc) => {
                const paymentRequest = doc.data();
                if (paymentRequest?.status === 'PAID') {
                    await updatePaymentStatus(true);
                    return;
                }
                if (paymentRequest?.errorCode === 'TM01') {
                    await updatePaymentStatus(false);
                    return;
                }
                if (
                    paymentRequest?.errorCode === 'BANKIDCL' ||
                    paymentRequest?.status === 'DECLINED'
                ) {
                    await updatePaymentStatus(false);
                    return;
                }
            }
        );
        return () => subscribeSwishPayment();
    }, []);

    useEffect(() => {
        if (error) {
            window.location.replace(window.location.origin + '/checkout/error');
        }
    }, [error]);

    const skipPayment = () => {
        navigate(`${nextPage}/${appointmentId}`);
    };

    if (loading) {
        return (
            <>
                <Header />
                <Wrapper>
                    <Skeleton>
                        <rect x="60" rx="10" ry="10" width="320" height="56" />
                        <rect x="60" y="70" rx="10" ry="10" width="320" height="56" />
                        <rect x="60" y="140" rx="10" ry="10" width="320" height="238" />
                        <rect y="400" x="140" rx="30" ry="30" width="162" height="83" />
                    </Skeleton>
                </Wrapper>
            </>
        );
    }
    if (success) {
        return (
            <>
                <Header />
                <Wrapper>
                    <Card sm>
                        <Box success>
                            <CheckIcon color={'#22C493'} />
                            <p className={styles.success_text}>{t('payment_successful')}</p>
                        </Box>
                    </Card>
                </Wrapper>
            </>
        );
    }
    return (
        <>
            <Header />
            <Wrapper>
                <h1>{t('payment_text')}</h1>
                <Card sm>
                    {!isMobile ? (
                        <div className={styles.payment_container}>
                            <Box success>
                                <p>{t('scan_swish_qr')}</p>
                            </Box>

                            <Box grey>
                                {!swishQrError && swishQr ? (
                                    <img src={swishQr} alt="" className={styles.swishQr} />
                                ) : (
                                    <p>{t('swish_qr_error')}</p>
                                )}
                            </Box>
                            <div className={styles.button_container}>
                                <Button onClick={() => navigate(-1)}>
                                    <p>{t('Tillbaka')}</p>
                                </Button>
                                <Button transparent onClick={skipPayment}>
                                    <p>{t('cancel_button_text')}</p>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.payment_container}>
                            <Box>
                                <p>{t('swish_button_header')}</p>
                            </Box>
                            <Button
                                onClick={() =>
                                    ((
                                        window as Window
                                    ).location = `swish://paymentrequest?token=${token}`)
                                }
                            >
                                {t('open_swish_button_text')}
                            </Button>
                            <Button onClick={() => navigate(-1)}>
                                <p>{t('Tillbaka')}</p>
                            </Button>
                            <Button transparent onClick={skipPayment}>
                                <p>{t('cancel_button_text')}</p>
                            </Button>
                        </div>
                    )}
                </Card>
            </Wrapper>
        </>
    );
};

export default Payment;
