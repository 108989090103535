/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './checkinSuccessful.module.scss';
import noShoes from '../../assets/no-shoes.png';
import handSanitizer from '../../assets/handSanitizer.png';
import { useSettings } from 'hooks/SettingsContext';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import useWindowSize from 'hooks/useWindowSize';
import { toDataURL } from 'qrcode';
import { useAuth } from 'hooks/AuthContext';
// import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { BookingType } from 'types';
import { CheckIcon } from 'components/icons/check';
import { Wrapper } from 'components/Wrapper';
// import Loading from 'components/Loading';
import { Box } from '../../components/Box';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import CurvedArrow from '../../assets/curved-arrow.png';

const CheckinSuccessful: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { isMobile } = useWindowSize();
    const { clinic } = useAuth();
    const [qrCodeImg, setQrCodeImg] = useState<string>();
    // const [surveyComplete, setSurveyComplete] = useState(true);
    // const [loading, updateLoading] = useState(true);
    const { appointmentId } = useParams();
    const [booking, setBooking] = useState<BookingType>();

    useEffect(() => {
        const opts = {
            quality: 0.3,
            margin: 1,
            color: {
                dark: '#000000',
                light: '#F8F8F8',
            },
        };
        const getQrCode = async (appointmentId: string) => {
            const nextPage = '/survey';
            const qrCode = await toDataURL(
                `${window.location.origin}/qr/${clinic}${nextPage}/${appointmentId}`,
                opts
            );
            setQrCodeImg(qrCode);
        };
        if (!appointmentId) return;
        getQrCode(appointmentId);

        if (localStorage.getItem('visitData') !== null) {
            const visitData = JSON.parse(localStorage.getItem('visitData') as string);
            setBooking(visitData.booking);
        }
    }, [appointmentId, clinic, settings]);

    // useEffect(() => {
    //     const isSurveyComplete = async (appointmentId: string) => {
    //         const db = getFirestore();
    //         const docRef = doc(db, 'appointments', appointmentId);
    //         const docData = await getDoc(docRef);
    //         const data = docData.data() as Appointment;
    //         setSurveyComplete(!!data.survey);
    //         updateLoading(false);
    //     };
    //     if (!appointmentId) return;
    //     isSurveyComplete(appointmentId);
    // }, [appointmentId, clinic, settings]);

    const infoIcons = settings?.config.isChildrenClinic
        ? [
              { img: noShoes, translationKey: 'check_in_successful_step_1' },
              { img: handSanitizer, translationKey: 'check_in_successful_step_2' },
          ]
        : [{ img: handSanitizer, translationKey: 'check_in_successful_step_2' }];

    return (
        <>
            <Header />

            <Wrapper>
                <Card>
                    <Box success start>
                        <CheckIcon color={'#22C493'} />
                        <div className={styles.checkin_success_text_container}>
                            <p className={styles.checkin_success_text}>
                                {t('check_in_complete_header')}
                            </p>
                            {booking ? (
                                <p className={styles.booking_info}>
                                    {t('booking_info_text')} {booking && booking.caregiver.title}{' '}
                                    {booking && booking.caregiver.firstName}{' '}
                                    {booking && booking.caregiver.lastName} kl.{' '}
                                    {booking && booking.startTime}
                                </p>
                            ) : null}
                        </div>
                    </Box>
                    <Box grey>
                        <div className={styles.qr_container}>
                            <div>
                                <p className={styles.qrTitle}>
                                    {settings?.config.screening && !isMobile
                                        ? t('before_appointment')
                                        : t('survey_successful_steps')}
                                </p>
                            </div>

                            <div className={styles.icons_row}>
                                {settings?.config.screening ? (
                                    <div className={styles.icons_wrapper}>
                                        <img
                                            src={CurvedArrow}
                                            alt=""
                                            className={styles.curved_arrow}
                                        />
                                        <div className={styles.icons_container}>
                                            <img
                                                className={styles.icon_image}
                                                src={qrCodeImg}
                                                alt=""
                                            />
                                        </div>
                                        <p style={{ color: 'red' }}>{t('survey_qr_red_text')}</p>
                                    </div>
                                ) : (
                                    infoIcons.map((icon, index) => (
                                        <div key={index} className={styles.icons_wrapper}>
                                            <div className={styles.icons_container}>
                                                <img
                                                    className={styles.icon_image}
                                                    src={icon.img}
                                                    alt=""
                                                />
                                            </div>
                                            <p>{t(icon.translationKey)}</p>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </Box>
                    {!isMobile ? (
                        <Button
                            onClick={() => {
                                if (!settings) return;
                                navigate('/screensaver');
                            }}
                            transparent
                        >
                            {t('check_in_successful_button_new_checkin')}
                        </Button>
                    ) : (
                        <p className={styles.checkin_complete_message_text}>
                            {t('close_window_message')}
                        </p>
                    )}
                </Card>
            </Wrapper>
        </>
    );
};

export default CheckinSuccessful;
