import { FC, useEffect, useState } from 'react';
import Numpad from 'components/Numpad';
import SecurityNumber from 'components/SecurityNumber';
import personnummer from 'personnummer';
import styles from './checkIn.module.scss';
import { useSettings } from 'hooks/SettingsContext';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/AuthContext';
import Header from 'components/Header';
import useWindowSize from 'hooks/useWindowSize';
import { getBookingService } from 'services/booking.service';
import ErrorModal from 'components/ErrorModal';
import { useNavigate } from 'react-router-dom';
import { getBooking, checkInPatient } from 'firebase-service';
import { v4 as uuidv4 } from 'uuid';
import { Wrapper } from '../../components/Wrapper';
import { Button } from '../../components/Button';
import { Skeleton } from '../../components/Skeleton/skeleton.component';

const CheckIn: FC = () => {
    const { clinic } = useAuth();
    const { settings } = useSettings();
    const [securityNumber, setSecurityNumber] = useState('');
    const [isCheckInButtonDisabled, setIsCheckInButtonDisabled] = useState(true);
    const [isNumpadDisabled, setIsNumpadDisabled] = useState(false);
    const [hasSecurityNumberError, setHasSecurityNumberError] = useState(false);
    const { t, i18n } = useTranslation();
    const { isMobile } = useWindowSize();
    const [loading, updateLoading] = useState(false);
    const [bookingError, updateBookingError] = useState(false);
    const [errormessage, updateErrorMessage] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        document.body.dir = 'ltr';
    }, []);
    useEffect(() => {
        localStorage.clear();
        if (securityNumber.length < 10) {
            setIsNumpadDisabled(false);
            setIsCheckInButtonDisabled(true);
            setHasSecurityNumberError(false);
        } else if (!personnummer.valid(securityNumber)) {
            setIsNumpadDisabled(true);
            setIsCheckInButtonDisabled(true);
            setHasSecurityNumberError(true);
        } else {
            setIsNumpadDisabled(true);
            setIsCheckInButtonDisabled(false);
            setHasSecurityNumberError(false);
        }
    }, [securityNumber]);
    localStorage.setItem('lang', i18n.language);
    const handleAppend = (value: number) => {
        setSecurityNumber(securityNumber + value);
    };
    const handleDelete = () => {
        setSecurityNumber(securityNumber.substring(0, securityNumber.length - 1));
    };

    const fireBaseCheckIn = async () => {
        if (!clinic || !settings) return;
        if (settings.careLabsBookingIntegration) {
            const bookings = await getBooking(securityNumber, clinic);
            if (bookings.length < 1) {
                updateBookingStatus(true, t('check_in_error_no_booking'));
                return;
            }
            navigate('/bookings', {
                state: {
                    bookings,
                    securityNumber,
                },
            });
            return;
        }

        const bookingId = uuidv4();
        const appointmentId = await checkInPatient(securityNumber, clinic, bookingId);
        const nextPage = isMobile ? '/survey' : '/checkin-successful';
        navigate(`${nextPage}/${appointmentId}`);
    };
    const updateBookingStatus = (error: boolean, message: string) => {
        updateLoading(!error);
        updateBookingError(error);
        updateErrorMessage(message);
    };
    const getBookings = async () => {
        if (!clinic || !settings) return;
        updateLoading(true);
        if (!settings.webDocIntegration) {
            updateLoading(false);
            await fireBaseCheckIn();
            return;
        }
        const res = await getBookingService(
            settings.webDocClinicId,
            securityNumber,
            settings.config.upcomingBookingsPeriod
        );
        if (res.status === 200 && res.data.length > 0) {
            updateLoading(false);
            navigate('/bookings', {
                state: {
                    bookings: res.data,
                    securityNumber,
                },
            });
        }

        if (res.status === 200 && res.data.length < 1) {
            updateBookingStatus(true, t('check_in_error_no_booking'));
            return;
        }
        if (res.error) {
            updateBookingStatus(true, res.error.message);
            return;
        }
    };
    const closeModal = () => {
        updateBookingError(false);
        setSecurityNumber('');
    };

    if (loading) {
        return (
            <>
                <Header />
                <Wrapper>
                    <Skeleton>
                        <rect x="20" rx="10" ry="10" width="450" height="50" />
                        <rect x="20" y="65" rx="10" ry="10" width="450" height="20" />
                        <rect x="20" y="110" rx="10" ry="10" width="450" height="250" />
                    </Skeleton>
                </Wrapper>
            </>
        );
    }

    return (
        <>
            <Header />
            <Wrapper>
                <h1 className={styles.title}>{t('check_in_title')}</h1>
                <p
                    className={`${
                        hasSecurityNumberError ? styles.errorMessage : styles.messageText
                    }`}
                >
                    {hasSecurityNumberError
                        ? t('check_in_error_invalid_social')
                        : t('check_in_text')}
                </p>
                {isMobile ? (
                    <div className={styles.securityNumberContainer}>
                        <p className={styles.secNumInitials}>
                            {settings?.config.securityNumberInitials?.map((secInit) => (
                                <span key={secInit}>{secInit}</span>
                            ))}
                        </p>
                        <input
                            type="tel"
                            placeholder="xxxxxxxxxx"
                            value={securityNumber}
                            onChange={(e) => {
                                if (e.target.value.length > 10) return;
                                setSecurityNumber(e.target.value);
                            }}
                            className={styles.securityNumberInput}
                        />
                    </div>
                ) : (
                    <>
                        <SecurityNumber
                            securityNumber={securityNumber}
                            hasSecurityNumberError={hasSecurityNumberError}
                            securityNumberInitials={settings?.config.securityNumberInitials ?? []}
                        />
                        <Numpad
                            handleAppend={handleAppend}
                            handleDelete={handleDelete}
                            hasDisabledNumberButtons={isNumpadDisabled}
                            hasDisabledBackspaceButton={securityNumber.length === 0}
                        />
                    </>
                )}
                <Button disabled={isCheckInButtonDisabled} onClick={getBookings}>
                    {t('start_button')}
                </Button>
                {bookingError ? (
                    <ErrorModal errorMessage={errormessage} closePopup={closeModal} />
                ) : null}
            </Wrapper>
        </>
    );
};
export default CheckIn;
