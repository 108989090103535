/* eslint-disable no-console */
import { useState } from 'react';
import { Booking } from 'components/Booking';
import { useSettings } from 'hooks/SettingsContext';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/AuthContext';
import Header from 'components/Header';
import { useNavigate } from 'react-router-dom';
import { checkInPatient } from 'firebase-service';
import { registerVisitService } from 'services/visit.service';
// import { getRecordTemplateDataService } from 'services/template.service';
import { useLocation } from 'react-router';
import { createInvoiceService } from 'services/invoice.service';
import { Card } from 'components/Card';
import { Wrapper } from 'components/Wrapper';
import { Button } from 'components/Button';
import useWindowSize from 'hooks/useWindowSize';
import ExclamationTriangle from '../../components/icons/ExclamationTriangle/exclamationTriangle';
import { Skeleton } from '../../components/Skeleton/skeleton.component';
import { Box } from '../../components/Box';
import { BookingType } from 'types';
import moment from 'moment';
import styles from './bookings.module.scss';
import { collection, doc, getFirestore, updateDoc } from 'firebase/firestore';

type Location = {
    bookings: BookingType[];
    securityNumber: string;
};

const Bookings = () => {
    const { clinic } = useAuth();
    const { settings } = useSettings();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, updateLoading] = useState(false);
    const { bookings, securityNumber } = location.state as Location;
    const { isMobile } = useWindowSize();
    document.body.dir = i18n.dir();

    const today = moment().format('YYYY-MM-DD');
    const now = new Date();
    const currentTime = moment(now)
        .subtract(settings?.config.lateArrivalMargin, 'minutes')
        .format('HH:mm');
    const todayAllBookings = bookings.filter((booking) => booking.date === today);
    const filteredByTimeBookings = todayAllBookings
        .filter((booking) => booking.startTime >= currentTime)
        .sort((a, b) => {
            if (a.startTime > b.startTime) {
                return 1;
            }
            if (a.startTime < b.startTime) {
                return -1;
            }
            return 0;
        });
    const upComingBookings = bookings
        .filter((booking) => booking.date > today)
        .sort((a, b) => {
            if (a.startTime > b.startTime) {
                return 1;
            }
            if (a.startTime < b.startTime) {
                return -1;
            }
            return 0;
        })
        .filter((booking, i) => i < 1);
    const firebaseCheckIn = async (booking: BookingType) => {
        if (!clinic || !settings || !booking) return;
        updateLoading(true);
        const db = getFirestore();
        const appointmentRef = doc(
            collection(db, 'clinicSettings', clinic, 'appointments'),
            todayAllBookings[0].id
        );
        await updateDoc(appointmentRef, {
            arrived: 1,
            status: 'checked in',
        });
        const appointmentId = appointmentRef.id;
        if (
            settings.hasPaymentsIntegration &&
            settings?.config.acceptPayment &&
            booking.payments[0].price > '0'
        ) {
            localStorage.setItem(
                'visitData',
                JSON.stringify({
                    invoiceData: booking.payments[0].price,
                    appointmentId,
                    booking,
                })
            );
            navigate('/checkout/pay');
            return;
        }
        const nextPage = isMobile ? '/survey' : '/checkin-successful';
        navigate(`${nextPage}/${appointmentId}`, {
            state: {
                appointmentId,
            },
        });
    };

    const webdocCheckIn = async (booking: BookingType) => {
        if (!clinic || !settings || !booking) return;
        updateLoading(true);
        const visitData = await registerVisitService(booking.id);
        const invoiceData =
            visitData.data.payments.length > 0 &&
            (await createInvoiceService(
                settings.webDocClinicId,
                visitData.data.id,
                visitData.data.payments[0].price
            ));
        // let screeningFields;
        // const screening = {} as ScreeningData;
        // if (settings.config.screening) {
        //     const recordTemplateId = visitData.data.recordTemplateId;
        //     const templateData =
        //         recordTemplateId && (await getRecordTemplateDataService(recordTemplateId));
        //     screeningFields =
        //         templateData &&
        //         templateData.data.keywords.map(({ id, title, information }: ScreeningFields) => {
        //             return {
        //                 id,
        //                 title,
        //                 information,
        //             };
        //         });
        //     templateData.data.keywords.forEach(
        //         ({ title }: ScreeningFields) => (screening[title] = '')
        //     );
        // }

        const patientName = [booking.patient.firstName, booking.patient.lastName];
        const appointmentId = await checkInPatient(
            securityNumber,
            clinic,
            booking.id,
            booking.caregiver.id,
            visitData.data.id,
            patientName
        );
        if (settings.config.acceptPayment && visitData.data.payments.length) {
            localStorage.setItem(
                'visitData',
                JSON.stringify({
                    invoiceData: invoiceData.data,
                    appointmentId,
                    userId: booking.caregiver.id,
                    visitId: visitData.data.id,
                    booking,
                })
            );

            navigate('/checkout/pay');
            return;
        }
        const nextPage = isMobile ? '/survey' : '/checkin-successful';
        navigate(`${nextPage}/${appointmentId}`, {
            state: {
                appointmentId,
                booking,
            },
        });
    };
    if (loading) {
        return (
            <>
                <Header />
                <Wrapper>
                    <Card>
                        <Skeleton>
                            <rect x="20" rx="10" ry="10" width="420" height="50" />
                            <rect x="20" y="65" rx="10" ry="10" width="420" height="20" />
                            <rect x="20" y="110" rx="10" ry="10" width="420" height="250" />
                        </Skeleton>
                    </Card>
                </Wrapper>
            </>
        );
    }
    return (
        <>
            <Header />
            <Wrapper>
                <h1>{t('booking_page_title')}</h1>
                <Card>
                    {filteredByTimeBookings.length > 0 ? (
                        <p className={styles.checkin_instruction_text}>
                            {t('click_booking_to_checkin')}
                        </p>
                    ) : null}

                    {filteredByTimeBookings.length > 0 ? (
                        <Booking
                            clinicName={settings && settings.config.clinicName}
                            key={filteredByTimeBookings[0].id}
                            booking={filteredByTimeBookings[0]}
                            checkIn={settings?.webDocIntegration ? webdocCheckIn : firebaseCheckIn}
                            active
                        />
                    ) : null}
                    {todayAllBookings.length > 0 && filteredByTimeBookings.length < 1 ? (
                        <Box grey>
                            <div className={styles.content_container}>
                                <ExclamationTriangle sm />
                                <p style={{ textAlign: 'center' }}>{t('late_arrival')}</p>
                            </div>
                        </Box>
                    ) : null}
                    {todayAllBookings.length < 1 ? (
                        <Box grey>
                            <div className={styles.content_container}>
                                <ExclamationTriangle sm />
                                <p style={{ textAlign: 'center' }}>{t('no_booking_today')}</p>
                            </div>
                        </Box>
                    ) : null}
                    {settings?.config.showUpcomingBookings && (
                        <>
                            <h4>{t('upcoming_booking')}</h4>
                            {upComingBookings.length > 0 ? (
                                upComingBookings.map((booking) => {
                                    return (
                                        <Booking
                                            clinicName={settings && settings.config.clinicName}
                                            key={booking.id}
                                            booking={booking}
                                            checkIn={
                                                settings?.webDocIntegration
                                                    ? webdocCheckIn
                                                    : firebaseCheckIn
                                            }
                                            active={false}
                                        />
                                    );
                                })
                            ) : (
                                <Box grey>
                                    <div className={styles.content_container}>
                                        <ExclamationTriangle sm />
                                        <p>{t('no_upcoming_booking_found')}</p>
                                    </div>
                                </Box>
                            )}
                        </>
                    )}

                    {!filteredByTimeBookings.length && (
                        <Button transparent onClick={() => navigate('/check-in')}>
                            {t('back_home_link')}
                        </Button>
                    )}
                </Card>
            </Wrapper>
        </>
    );
};

export default Bookings;
