/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { BookingType } from 'types';
import styles from './booking.module.scss';
import { useTranslation } from 'react-i18next';
import doctorIcon from '../../assets/doctor.png';
import MapIcon from '../../assets/map.png';
import ClockIcon from '../../assets/clock.png';

type Props = {
    booking: BookingType;
    checkIn: (booking: BookingType) => Promise<any>;
    active?: boolean;
    clinicName?: string;
};

export const Booking: FC<Props> = ({ clinicName, booking, checkIn, active }) => {
    const { t } = useTranslation();
    return (
        <div
            className={active ? styles.container : styles.container_disabled}
            onClick={active ? () => checkIn(booking) : undefined}
        >
            <div className={styles.booking_container}>
                <div className={active ? styles.button : styles.button_blue}>
                    <p>{active ? t('today_booking') : t('upcoming_text')}</p>
                </div>
                <div className={styles.information_container}>
                    <img src={MapIcon} alt="" />
                    <p>{clinicName}</p>
                </div>
                <div className={styles.information_container}>
                    <img src={ClockIcon} alt="" />
                    <p>{booking.date}</p>
                    <p>{booking.startTime}</p>
                </div>

                <div className={styles.information_container}>
                    <img src={doctorIcon} height="35px" alt="" />
                    <p>{booking.caregiver.firstName}</p>
                    <p>{booking.caregiver.lastName}</p>
                    <p>{booking.caregiver.title}</p>
                </div>
            </div>
            {active && <button className={styles.checkInButton}>{t('check_in_button')}</button>}
        </div>
    );
};
