import { FC, ReactNode } from 'react';
import styles from './box.module.scss';

type Props = {
    active?: boolean;
    disabled?: boolean;
    grey?: boolean;
    success?: boolean;
    error?: boolean;
    start?: boolean;
    children: ReactNode;
};

export const Box: FC<Props> = ({ active, disabled, grey, success, error, start, children }) => {
    if (active) {
        return <div className={styles.active}>{children}</div>;
    }
    if (disabled) {
        return <div className={styles.disabled}>{children}</div>;
    }
    if (grey) {
        return <div className={styles.grey}>{children}</div>;
    }
    if (success && start) {
        return <div className={styles.success_start}>{children}</div>;
    }
    if (success) {
        return <div className={styles.success}>{children}</div>;
    }

    if (error) {
        return <div className={styles.error}>{children}</div>;
    }
    return <div className={styles.box}>{children}</div>;
};
