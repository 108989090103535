import React, { FC, useEffect, useState } from 'react';
import Header from 'components/Header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './checkout.module.scss';
import { useSettings } from 'hooks/SettingsContext';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'hooks/useWindowSize';
import { Card } from 'components/Card';
import { Button } from 'components/Button';
import { createSwishPaymentService } from 'services/swish.service';
import { swishCallbackUrl } from 'config/endPoints';
import Loading from 'components/Loading';
import SwishLogo from '../../assets/swish-logo.svg';
import KlarnaLogo from '../../assets/klarna.png';
import { initiateKlarnaPayment } from 'services/klarna.payment.service';
import { useParams } from 'react-router';
import { Wrapper } from 'components/Wrapper';
import { CheckIcon } from 'components/icons/check';
import { updateInvoiceService } from 'services/invoice.service';
import { sendInvoiceErrorNotification } from '../../services/notification.service';
import { updateFirestoreAppointment } from 'firebase-service';
import { Box } from '../../components/Box';
import { Skeleton } from '../../components/Skeleton/skeleton.component';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useAuth } from 'hooks/AuthContext';

const Checkout: FC = () => {
    const { i18n } = useTranslation();
    const { clinic } = useAuth();
    const [loading, updateLoading] = useState(false);
    const [error, updateError] = useState(false);
    const [success, updateSuccess] = useState(false);
    const [errorMessage, updateErrorMessage] = useState('');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { isMobile } = useWindowSize();
    const [selectedPaymentMethod, updateSelectedPaymentMethod] = useState('swish');
    const { status } = useParams();
    const visitData = JSON.parse(localStorage.getItem('visitData') as string) ?? '';
    const { invoiceData, appointmentId } = visitData;
    const invoiceId = settings?.webDocIntegration ? invoiceData[0].invoiceId : '';
    const invoiceNumber = settings?.webDocIntegration ? invoiceData[0].invoiceNumber : '';
    const netAmount = invoiceData[0].netAmount ?? invoiceData;
    const nextPage = isMobile ? '/survey' : '/checkin-successful';
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get('order_id');
    useEffect(() => {
        const lang = localStorage.getItem('lang');
        if (lang !== null) {
            i18n.changeLanguage(lang);
        }
    }, []);

    const updateInvoiceStatus = async () => {
        if (!settings) return;
        const res = await updateInvoiceService(settings.webDocClinicId, invoiceId, netAmount);

        if (res.error) {
            await sendInvoiceErrorNotification(
                invoiceNumber,
                settings.config.email,
                t('invoice_failure_notification'),
                t('invoice_failure_subject')
            );
        }
    };
    const updatePaymentStatus = async () => {
        if (!clinic) return;
        await updateFirestoreAppointment(clinic, appointmentId, { arrived: 2, invoiceNumber });
        if (settings?.webDocIntegration) {
            await updateInvoiceStatus();
        }
        navigate(`${nextPage}/${appointmentId}`);
    };
    useEffect(() => {
        if (!orderId) return undefined;
        const db = getFirestore();
        const subscribeKlarnaPayment = onSnapshot(
            doc(db, 'klarnaPayments', orderId),
            async (doc) => {
                const paymentRequest = doc.data();
                if (paymentRequest?.capture.captured_amount === +netAmount) {
                    updateSuccess(true);
                }
            }
        );

        return () => subscribeKlarnaPayment();
    }, [orderId]);

    useEffect(() => {
        if (success) {
            updatePaymentStatus();
        }
    }, [settings, success]);

    const updatePaymentMethod = (option: string) => {
        updateSelectedPaymentMethod(option);
    };

    const body = {
        callbackUrl: swishCallbackUrl,
        payeeAlias: settings?.swishNumber as string,
        currency: 'SEK',
        amount: netAmount.toString(),
    };

    const startPayment = async () => {
        updateLoading(!loading);
        if (!settings) return;
        if (selectedPaymentMethod === 'swish') {
            const response = await createSwishPaymentService(body);
            if (response.status === 200 && response.data.id) {
                const { id, token, location }: { [key: string]: string } = response.data;
                localStorage.setItem('swishData', JSON.stringify({ id, token, location }));
                updateLoading(false);
                navigate('/payment');
            }
            if (response.error) {
                updateError(true);
                updateErrorMessage(response.message);
            }
            return;
        }
        if (selectedPaymentMethod === 'klarna') {
            const response = await initiateKlarnaPayment(
                window.location.origin,
                netAmount,
                `${settings.config.clinicName} visit`
            );
            if (response.redirect_url) {
                window.location.href = response.redirect_url;
            }
            return;
        }
    };
    if (loading) {
        return (
            <>
                <Header />
                <Wrapper>
                    <Skeleton>
                        <rect x="60" rx="10" ry="10" width="320" height="56" />
                        <rect x="60" y="70" rx="10" ry="10" width="320" height="56" />
                        <rect x="60" y="140" rx="10" ry="10" width="320" height="238" />
                        <rect y="400" x="140" rx="30" ry="30" width="162" height="83" />
                    </Skeleton>
                </Wrapper>
            </>
        );
    }
    if (status === 'success') {
        return (
            <>
                <Header />
                <Wrapper>
                    <Card>
                        <Box success>
                            <CheckIcon color={'#22C493'} />
                            <p className={styles.success_text}>{t('payment_successful')}</p>
                        </Box>
                    </Card>
                </Wrapper>
            </>
        );
    }

    return (
        <>
            <Header />
            {loading ? (
                <Loading loading={loading} />
            ) : (
                <Wrapper>
                    <h1>{t('checkout_page_title')}</h1>

                    <Card>
                        <div className={styles.payment_method_list}>
                            {status === 'pay' && (
                                <Box success>
                                    <h5>{t('check_in_successful_header')}</h5>
                                </Box>
                            )}
                            {status !== 'pay' && status !== 'success' && (
                                <Box error>
                                    <h5>{t('payment_error_text')}</h5>
                                </Box>
                            )}
                            {error && (
                                <Box error>
                                    <h5>{errorMessage}</h5>
                                </Box>
                            )}
                            {/* <h3>{t('payment_method')}</h3> */}
                            <p>{netAmount} SEK</p>

                            {settings && settings.paymentMethods.includes('swish') ? (
                                <Box active={selectedPaymentMethod === 'swish'}>
                                    <div
                                        className={styles.payment_method_container}
                                        onClick={() => updatePaymentMethod('swish')}
                                    >
                                        <div className={styles.radio_logo_container}>
                                            <div className={styles.radio_button}>
                                                <input
                                                    type="radio"
                                                    readOnly
                                                    checked={selectedPaymentMethod === 'swish'}
                                                />
                                                <p className={styles.bold_text}>Swish</p>
                                            </div>
                                            <div className={styles.logo_container}>
                                                <img src={SwishLogo} alt="" width="60px" />
                                            </div>
                                        </div>
                                        {selectedPaymentMethod === 'swish' && (
                                            <div className={styles.text_container}>
                                                <p>{t('swish_bankid_info_text')}</p>
                                            </div>
                                        )}
                                    </div>
                                </Box>
                            ) : null}
                            {settings && settings.paymentMethods.includes('klarna') ? (
                                <Box active={selectedPaymentMethod === 'klarna'}>
                                    <div
                                        onClick={() => updatePaymentMethod('klarna')}
                                        className={styles.payment_method_container}
                                    >
                                        <div className={styles.radio_logo_container}>
                                            <div className={styles.radio_button}>
                                                <input
                                                    type="radio"
                                                    checked={selectedPaymentMethod === 'klarna'}
                                                    readOnly
                                                />
                                                <p className={styles.bold_text}>Klarna</p>
                                            </div>
                                            <div className={styles.logo_container}>
                                                <img src={KlarnaLogo} alt="" width="50px" />
                                            </div>
                                        </div>
                                        {selectedPaymentMethod === 'klarna' && (
                                            <div className={styles.text_container}>
                                                <p>{t('klarna_description_text')}</p>
                                            </div>
                                        )}
                                    </div>
                                </Box>
                            ) : null}
                        </div>
                        <div className={styles.button_container}>
                            <Button onClick={startPayment}>
                                <p>{t('pay_button_text')}</p>
                            </Button>
                            <Button
                                onClick={() => {
                                    if (!settings) return;
                                    const nextPage = isMobile
                                        ? `/survey/${appointmentId}`
                                        : `/checkin-successful/${appointmentId}`;
                                    navigate(`${nextPage}`);
                                }}
                                transparent
                            >
                                <p>{t('cancel_button_text')}</p>
                            </Button>
                        </div>
                    </Card>
                </Wrapper>
            )}
        </>
    );
};

export default Checkout;
