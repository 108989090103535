import { FC, useEffect, useState } from 'react';
import LanguageToggle from 'components/LanguageToggle';
import Clock from 'components/Clock';
import styles from './screensaver.module.scss';
import { useNavigate } from 'react-router-dom';
import pointingHand from 'assets/pointing_hand.svg';
import { useSettings } from 'hooks/SettingsContext';
import { useTranslation } from 'react-i18next';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'firebase-service';
import { useAuth } from 'hooks/AuthContext';
import Header from 'components/Header';
import useWindowSize from 'hooks/useWindowSize';
import { toDataURL } from 'qrcode';
import { Wrapper } from '../../components/Wrapper';

const Screensaver: FC = () => {
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { t } = useTranslation();
    const { clinic } = useAuth();
    const { isMobile } = useWindowSize();
    const [qrCodeImg, setQrCodeImg] = useState<string>();

    useEffect(() => {
        const opts = {
            quality: 0.3,
            margin: 1,
            color: {
                dark: '#000000',
                light: '#ffffff',
            },
        };
        const getQrCode = async (clinic: string) => {
            const qrCode = await toDataURL(
                window.location.origin + '/qr/' + clinic + '/check-in',
                opts
            );
            setQrCodeImg(qrCode);
        };
        if (!clinic) return;
        getQrCode(clinic);
    }, [clinic]);

    return (
        <>
            <Header />
            <Wrapper gradient>
                <div className={styles.welcomeTextContainer}>
                    <p className={styles.screensaverWelcomeText}>{t('welcome_text')}</p>
                    <p className={styles.screensaverInstructions}>
                        {settings?.config.survey
                            ? t('screensaver_instructions_qr')
                            : t('screensaver_instructions')}
                    </p>
                </div>
                <div
                    className={styles.screensaverCircleOuter}
                    onClick={() => {
                        if (!settings) return;
                        logEvent(analytics, 'screensaver_pressed', { clinic });
                        navigate('/check-in');
                    }}
                >
                    <div className={styles.screensaverCircleInner}>
                        <p>{t('screensaver_start_text')}</p>
                        <div
                            className={`${styles.screensaverPointingHandWrapper} ${styles.screensaverPointingHandWrapperPulse}`}
                        >
                            <img
                                className={styles.screensaverPointingHand}
                                src={pointingHand}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                {!isMobile && qrCodeImg && settings?.config.survey && (
                    <div className={styles.qrContainer}>
                        <img src={qrCodeImg} className={styles.screensaverQRCode} alt="" />
                    </div>
                )}
                <div className={styles.screensaverClockAndLanguageBar}>
                    <Clock />
                    <LanguageToggle />
                </div>
            </Wrapper>
        </>
    );
};

export default Screensaver;
