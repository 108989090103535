/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from 'moment';

export const getStartDate = () => {
    const today = new Date();
    return moment(today).format('YYYY-MM-DD');
};

export const getEndDate = (days: number) => {
    return moment().add(days, 'days').format('YYYY-MM-DD');
};
