/* eslint-disable indent */
import { initializeApp } from 'firebase/app';
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    orderBy,
    query,
    Timestamp,
    updateDoc,
    where,
} from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
    getAuth,
    signInWithEmailAndPassword,
    signInAnonymously,
    UserCredential,
    browserSessionPersistence,
    setPersistence,
} from 'firebase/auth';
import { Appointment, Clinic } from 'types';
import moment from 'moment';
import 'moment/locale/sv';
import { encrypt } from './crypto';
import { getStartDate } from 'helpers/formateDate';

moment.locale('se');

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const organization = 'carelabs.se';

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
const db = getFirestore();
export const analytics = getAnalytics(app);

export const signInWithEmailPassword = async (
    email: string,
    password: string
): Promise<UserCredential> => {
    await setPersistence(authentication, browserSessionPersistence);
    return signInWithEmailAndPassword(authentication, email, password);
};

export const signInAnonymouslyQR = async (clinicString: string): Promise<UserCredential | null> => {
    await setPersistence(authentication, browserSessionPersistence);
    const docRef = doc(db, 'clinics', clinicString);
    const docSnapshot = await getDoc(docRef);
    if (!docSnapshot.exists) return null;
    const clinic = docSnapshot.data() as Clinic;
    if (!clinic.active) return null;
    return signInAnonymously(authentication);
};

export const checkInPatient = async (
    securityNumber: string,
    clinic: string,
    bookingId: string,
    staffId?: string,
    visitId?: string,
    patientName?: string[]
    // eslint-disable-next-line max-params
): Promise<string> => {
    const currentTimestamp = new Timestamp(Date.now() / 1000, 0);
    const colAppointments = collection(db, 'clinicSettings', clinic, 'appointments');
    const encryptedSecurityNumber = encrypt(securityNumber);
    const appointmentQuery = query(
        colAppointments,
        where('patient.socialSec', '==', encryptedSecurityNumber),
        where('status', '==', 'created'),
        where('appointmentAt', '>=', currentTimestamp),
        orderBy('appointmentAt')
    );
    const snapshot = await getDocs(appointmentQuery);
    const appointments = snapshot.docs.map((appointment) => {
        return { id: appointment.id, ...appointment.data() };
    }) as Appointment[];

    const appointmentTodayClosestInTime = appointments[0];

    if (appointmentTodayClosestInTime) {
        const docRef = doc(
            collection(db, 'clinicSettings', clinic, 'appointments'),
            appointmentTodayClosestInTime.id
        );
        await updateDoc(docRef, {
            status: 'checked in',
            updatedAt: new Timestamp(Date.now() / 1000, 0),
        });
        logEvent(analytics, 'check-in-successful', {
            clinic,
            patient: encryptedSecurityNumber,
        });
        return docRef.id;
    }
    const docRef = await addDoc(colAppointments, {
        clinic,
        appointmentAt: new Timestamp(Date.now() / 1000, 0),
        createdAt: new Timestamp(Date.now() / 1000, 0),
        updatedAt: new Timestamp(Date.now() / 1000, 0),
        patient: patientName
            ? {
                  socialSec: encryptedSecurityNumber,
                  firstName: patientName[0],
                  lastName: patientName[1],
              }
            : { socialSec: encryptedSecurityNumber },
        status: 'checked in',
        published: null,
        type: 'unscheduled',
        bookingId,
        staffId: staffId ?? null,
        visitId: visitId ?? null,
    });
    logEvent(analytics, 'check-in-successful', { clinic, patient: encryptedSecurityNumber });
    return docRef.id;
};

export const getBooking = async (
    securityNumber: string,
    clinic: string
): Promise<Appointment[]> => {
    const colAppointments = collection(db, 'clinicSettings', clinic, 'appointments');
    const encryptedSecurityNumber = encrypt(securityNumber);
    const startDate = getStartDate();
    const appointmentQuery = query(
        colAppointments,
        where('patient.socialSec', '==', encryptedSecurityNumber),
        where('arrived', '==', 0),
        where('clinic', '==', clinic),
        where('type', '==', 'scheduled'),
        where('date', '>=', startDate),
        orderBy('date')
    );

    const snapshot = await getDocs(appointmentQuery);
    const appointments = snapshot.docs.map((appointment) => {
        return { id: appointment.id, ...appointment.data() };
    }) as Appointment[];
    return appointments;
};

export const updateFirestoreAppointment = async (
    clinic: string,
    appointmentId: string,
    data: { [key: string]: string | { [key: string]: string } | number | boolean }
) => {
    const db = getFirestore();
    const docRef = doc(collection(db, 'clinicSettings', clinic, 'appointments'), appointmentId);
    await updateDoc(docRef, data);
    return;
};
